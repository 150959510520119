// Customize charte colors
$bleuMarine :           #0e192d;
$bleuPetrole:           #163641;
$jaune:                 #fbbb3f;
$orange:                #eb6b2e;
$bordeaux:              #7b1425;
$bleuCanard:            #18a096;
$beige:                 #cbba9e;
$darkBlue:              #001934;    // bg menu, text, icons
$argent:                #e6e6e6;    // input fields, bouton "plus d\'info"
$lightGrey:             #cccccc;
$bleuAcier:             #067f9f;    // Bouton "Ajouter au panier", badge secondary                           
                        
$stage1-color:          $beige;
$stage2-color:          $bleuCanard;
$stage3-color:          $bleuMarine;
$stage4-color:          $bleuPetrole;
$stage5-color:          $bordeaux;
$stage6-color:          $jaune;
$stage7-color:          $orange;

$body-bg:               white;
$main-bg-color:         white;

$table-bg:              $argent;
$table-bordered-bg:     white;
$table-border:          $argent;
$table-mb:              1rem;
$table-success:         #c3e6cb;     
$table-info:            #bee5eb;
$table-warning:         #ffeeba; 

$badge-success:         #c3e6cb;         
$badge-info:            #17a2b8;
$badge-warning:         #ffeeba;

$paiement-success:      #5cb85c;         
$paiement-info:         #5bc0de;
$paiement-warning:      #f0ad4e;
$paiement-danger:       #d9534f;

$step:                  #cccccc;
$step-current:          $bleuAcier;

$card-spacer-x:         0.3rem;       /* aka card-body padding */
$card-bg:               $argent;
$card-bg-color:         transparent;
$card-border-color:     $argent; 
$card-border-bottom:    0;

// Customize Bootstrap variables 
$font-family-base:          'Muli', sans-serif; 
$font-size-base:            1rem;
$font-weight-base:          400;
$line-height-base:          1.5;
$body-color:                $darkBlue;
$spacer:                    1rem;
$hr-border-width:           0;        

$link-color:                darken($darkBlue, 10%);
$link-decoration:           none;
$link-hover-color:          darken($link-color, 15%);
$link-hover-decoration:     underline;

$headings-margin-bottom:    ($spacer / 2);
$headings-font-family:      inherit;
$headings-font-weight:      900;
$headings-line-height:      1.2;
$headings-color:            inherit;

$h1-font-size:              $font-size-base * 1.6;
$h2-font-size:              $font-size-base * 1.5;
$h3-font-size:              $font-size-base * 1.4;
$h4-font-size:              $font-size-base * 1.3;
$h5-font-size:              $font-size-base * 1.2;
$h6-font-size:              $font-size-base * 1;

// Navbar
$navbar-bg:                     $darkBlue;
$navbar-text:                   white;
$navbar-text-link:              white;
$navbar-text-link-over:         white;
$navbar-nav-link:               white;
$navbar-nav-link-over:          white;
$navbar-toggler:                $bleuAcier;
$navbar-collapse-border:        white;
$navbar-light-active-color:     $bleuAcier;
$navbar-light-active-color:     $bleuAcier;

// Image thumbnails
$thumbnail-padding:             0;
$thumbnail-bg:                  white;
$thumbnail-border-width:        0;
$thumbnail-border-radius:       0;

// Forms
$form-control-bg:           $argent;
$input-color:               $darkBlue; 
$input-bg:                  $argent;             
$input-border-width:        1px;        
$input-border-color:        $argent;
$input-border-radius:       0;


// Bootstrap and its default variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";


// Customize Font Awesomes
$fa-css-prefix:         fa;
.#{$fa-css-prefix}-1x {
  font-size: ($font-size-base * 1.3);
}
.#{$fa-css-prefix}-2x {
  font-size: ($font-size-base *1.6);
}
.#{$fa-css-prefix}-3x {
  font-size: ($font-size-base *2);
}
.#{$fa-css-prefix}-4x {
  font-size: ($font-size-base *3);
}
